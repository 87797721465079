// Types
import { DOT } from '../../classes/dot.class';
import { KeyValuePairs } from '../../types/common';
import { Data } from '../../types/hit';
// Methods
import { getDataDot, getDataDotData } from '../utils/html/traversing';
import { setWindowNameWithId } from '../init';
import { getGeometry } from '../geometry/geometry';
// Constants
import { EVENTS } from '../../constants/index';

/**
 * Sets referrer for SPA website
 * @param dot DOT instance
 */
export const _getSPAReferrer = (dot: DOT): string => {
  const referrer = dot.SPAReferrer || document.referrer;
  dot.SPAReferrer = window.location.href;
  return referrer;
};

/**
 * Adds impressData to data object (d: {...}).
 * @param {Object} dataObject
 * @param {Object} impressData
 */
export const _addImpressData = (dataObject: Data, impressData: KeyValuePairs<unknown>): Data => {
  if (!dataObject.d) {
    dataObject.d = {};
  }

  for (const key in impressData) {
    dataObject.d[key] = impressData[key];
  }

  return dataObject;
};

/**
 * Sets current status for impressBeforeAdloadSent
 * @param dot DOT instance
 * @param status status to be set
 */
export const setImpressBeforeAdload = (dot: DOT, status: boolean): void => {
  if (window.DOT) {
    window.DOT.impressBeforeAdloadSent = status;
  }
  dot.impressBeforeAdloadSent = status;
};

/**
 * Sends impress hit
 *
 * @param dot DOT instance
 * @param callback called when hit is sent
 * @param impressData additional data which will be added to hit data (d: {...})
 */
export const sendImpress = (dot: DOT, callback: () => void = null, impressData?: KeyValuePairs<unknown>): void => {
  const data: Data = {
    q: dot._cfg.query,
    d: {
      tid: window.name,
      referer: dot._cfg.spa ? _getSPAReferrer(dot) : document.referrer,
      path: getDataDot(document.body),
    },
  };

  if (dot._cfg.spa) {
    setWindowNameWithId(dot);
  }

  if (impressData) {
    _addImpressData(data, impressData);
  } else {
    data.d = { ...data.d, ...getDataDotData(document.body) };
  }

  data.d = { ...data.d, ...getGeometry(dot.dataElms) };
  dot.hit('impress', data, callback);

  // dispatch event for the first impress on page
  if (!window.DOT?.firstImpressSent) {
    window.dispatchEvent(new CustomEvent(EVENTS.FIRST_IMPRESS_SENT));
  }

  dot.firstImpressSent = true;
  setImpressBeforeAdload(dot, true);
};

/**
 * If cookieRequest done, sends impress hit, otherwise adds load event to cookieRequest
 * @param dot DOT instance
 * @param callback called when impress is sent
 */
export const handleImpress = (dot: DOT, callback?: () => void): void => {
  if (dot.cookieRequestDone) {
    dot.impress(callback, null);
    return;
  }

  const impressHandler = () => {
    dot.cookieRequest.removeEventListener('load', impressHandler);
    dot.cookieRequestDone = true;
    dot.impress(callback, null);
  };

  dot.cookieRequest.addEventListener('load', impressHandler);
};

// API
export default { sendImpress, handleImpress, setImpressBeforeAdload };
