// Types
import { DOT } from '../../classes/dot.class';
import { KeyValuePairs } from '../../types/common';

// IVA packages
import { IDENTITIES, IDENTITIES_SIMPLIFIED } from '@iva/types/identity-storage';
import IS from '@iva/identity-storage';

type ChangeData = {
  changes: string[];
  identities: IDENTITIES | IDENTITIES_SIMPLIFIED;
};

/**
 * Sets default value for dot._userChanged
 * @param dot DOT instance
 */
export const resetUserChangeStatus = (dot: DOT): void => {
  dot.userChanged = false;
};

/**
 * Checks if specific identity has been changed
 * @param key identity key
 * @param data change data
 */
const _hasIdentityChanged = (key: string, data: ChangeData): boolean => {
  if (!data?.changes) {
    return false;
  }

  return data.changes.includes(key);
};

/**
 * Handles consent changes
 * @param dot DOT instance
 */
const _consentHandler = (dot: DOT, data: ChangeData): void => {
  const consentCookie = data?.identities?.consent;

  if (!consentCookie) {
    return;
  }

  // reset userChange flag and save current consent
  resetUserChangeStatus(dot);
  dot.consent = consentCookie as string;
};

/**
 * Handles rusId (user) changes
 * @param dot DOT instance
 */
const _userHandler = (dot: DOT, data: ChangeData) => {
  const login = data?.identities?.login ? (data?.identities?.login as KeyValuePairs<unknown>) : {};

  // if new rusId, sets userChange flag
  if (login.uid && dot._cfg.rusId && login.uid !== dot._cfg.rusId) {
    dot.userChanged = true;
  }

  if (login.advert_uid && dot._cfg.said && login.advert_uid !== dot._cfg.said) {
    dot.userChanged = true;
  }
};

/**
 * Handles IS events for login and consent changes
 * @param dot DOT instance
 */
export const observeIdentityChanges = (dot: DOT): void => {
  const handler = (data: ChangeData): void => {
    if (_hasIdentityChanged('consent', data)) {
      _consentHandler(dot, data);
    }

    if (_hasIdentityChanged('login', data)) {
      _userHandler(dot, data);
    }
  };

  IS.subscribeIdentities(handler);
};

/**
 * Handles user change during configuration
 * @param customConfig dot config object
 */
export const handleUserChange = (customConfig?: KeyValuePairs<unknown>): void => {
  if (!window.DOT) {
    return;
  }

  // rusId/said firstly changed -> not considered as user change
  if (!window.DOT._cfg.rusId && !window.DOT._cfg.said) {
    return;
  }

  if (customConfig?.rusId && customConfig.rusId !== window.DOT._cfg.rusId) {
    window.DOT.userChanged = true;
  }

  if (customConfig?.said && customConfig.said !== window.DOT._cfg.said) {
    window.DOT.userChanged = true;
  }
};

/**
 * Checks if consent string from cookie can be used in hit
 * @param consentCookieValue encoded consent string
 */
export const canUseCookieConsent = (consentCookieValue: string): boolean => {
  if (!window.DOT) {
    return false;
  }

  // when cookie already exists but not initialized in DOT instance
  if (!window.DOT.consent) {
    window.DOT.consent = consentCookieValue;
  }

  // user hasn't changed
  if (!window.DOT.userChanged) {
    return true;
  }

  // Consent has already changed too (as rusId). We can append it to hit.
  if (window.DOT.consent !== consentCookieValue) {
    window.DOT.consent = consentCookieValue;
    resetUserChangeStatus(window.DOT);
    return true;
  }

  // Consent belongs to previous user. We cannot append it to hit
  return false;
};

// API
export default { resetUserChangeStatus, handleUserChange, canUseCookieConsent };
