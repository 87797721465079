// Types
import { DOT } from '../../classes/dot.class';
import { HandleSidOutput } from '../../types/common';
// Methods
import { getSidCookie, setCookieAndReport } from '../utils/cookie/cookie';
import { getZnStringFromLocationSearch, getParameterFromURLQuery } from '../utils/general/url';
import { isSbrowser } from '../utils/general/browser';
import { shouldUseRedirect } from '../redirect/redirect';
import { shouldDisableCookieRefresh, createCookieRequest, hasAlreadySetSid, saveSidIntoBrowser } from './sid';
import { setToLocalStorage } from '../utils/storage/storage';
import { updateSidIdentity } from '../identityObject/identityObject';
import { CONSENT, SID_STORE_CHECK } from '@sklik/cmp2-common';
// IVA libraries
import { handleSbrowserSid } from '@iva/sbrowser-sid';
import { runLinkdecoration } from '@iva/linkdecoration';
import { SID_UTILS } from '@iva/utils';

/**
 * Tries to synchronise SID cookie in Sbrowser
 * @param sid cookie value
 */
export const _handleSbrowserPostmessage = (dot: DOT, sid?: string): HandleSidOutput => {
  if (!sid) {
    dot.log('Sbrowser: Sid not available');
    return -1;
  }

  const APP_DATA_CONSENT_REGEX = /AppDataConsent:\s*false/;
  if (APP_DATA_CONSENT_REGEX.test(sid)) {
    dot.log('Sbrowser: "AppDataConset" is set to false, ignoring the sid');
    return 1;
  }

  if (!sid.includes('te=')) {
    dot.log('Sbrowser: Sid not valid');
    return -1;
  }

  try {
    SID_STORE_CHECK.sidLegalStoreCheck(() => {
      setToLocalStorage('sid', sid);
      updateSidIdentity(sid);
      setCookieAndReport({
        name: 'sid',
        value: sid,
        expires: [3, 'days'],
      });
    });
  } catch (e) {
    return 1;
  }

  dot.log('Sbrowser: SID cookie synchronised');
  dot.log(`Sbrowser: show sid: ${sid}`);
  return 0;
};

/**
 * Synces sid cookie to local domain
 * @param dot DOT instance
 */
export const syncSid = (dot: DOT): void => {
  if (dot.synced || window?.DOT?.synced) {
    // main DOT already synced sid, no need to do it again
    dot.log(`SID is already synced`);
    dot.cookieRequestDone = true;
    return;
  }

  dot.synced = true;

  let sbrowserSyncCalled = false;
  if (isSbrowser()) {
    dot._handleSid = (sid?: string): void => {
      _handleSbrowserPostmessage(dot, sid);
    };

    if (handleSbrowserSid()) {
      sbrowserSyncCalled = true;
    }
  }

  // call linkdecoration
  runLinkdecoration(null, 30000);

  // cut sid from redirect back url if exists
  const znstring = getZnStringFromLocationSearch(dot);

  if (hasAlreadySetSid()) {
    // LINKDECORATION
    dot.log('linkdecoration has already set SID cookie');
    dot.log('saving SID from cookie to localstorage');
    setToLocalStorage('sid', getSidCookie());
    dot.cookieRequestDone = true;
  } else if (znstring) {
    // CMP REDIRECT
    const sidValue = SID_UTILS.hashToSid(znstring);

    if (!sidValue) {
      dot.log(`Unable to get sid from znstring - invalid hash: ${znstring}`);
      dot.cookieRequestDone = true;
      return;
    }
    dot.log(`Unhashed sid from redirect: ${sidValue}`);

    const tcParameter = getParameterFromURLQuery('_tc');
    const tcString = tcParameter ? decodeURIComponent(window.atob(tcParameter)) : null;

    dot.cookieRequestDone = true;

    if (CONSENT.hasPurposeOne(tcString) || CONSENT.hasPurposeOneFromCookieList()) {
      dot.log(`SID from redirect taken: ${sidValue}`);
      try {
        saveSidIntoBrowser(dot, sidValue);
      } catch (e) {
        // no op
        return;
      }
    }
  } else if (sbrowserSyncCalled || shouldUseRedirect()) {
    // SBROWSER OR SHOULD REDIRECT
    dot.cookieRequestDone = true;
    dot.log('sync already called by Sbrowser OR redirect should sync sid');
  } else {
    if (shouldDisableCookieRefresh(dot._cfg.serverSideRefresher)) {
      // SERVERSIDE REFRESHER
      dot.log('disabling cookie refresher');
      if (document?.body) {
        document.body.setAttribute('data-dot-cookie-refresh-disabled', '');
      }
      dot.cookieRequestDone = true;
    } else {
      // COOKIE REQUEST
      dot.log('creating SID cookie request');
      createCookieRequest(dot);
    }
  }
};

export default { syncSid };
