// Classes
import { DOT } from '../../../classes/dot.class';
// Types
import { Config } from 'types/config';
import { GetInfoRequestEvent, GetInfoResponseEvent } from '../../../types/iframe';
// Constants
import { CFG_FROM_TOP_FRAME_TIMEOUT, EVENTS } from '../../../constants';

// are we in the top level?
export const isTopLevel = (win: Window = window): boolean => {
  try {
    return (win.top === win.self || !!win.Cypress) && !win.frameElement;
  } catch (_) {
    return win.top === win.self;
  }
};

// get top level window reference
export const getTopWindow = (win: Window = window): Window => (isTopLevel(win) ? win : getTopWindow(win.parent));

export const handlerFromChildMessage = (event: MessageEvent<GetInfoRequestEvent>, dot: DOT) => {
  if (event.source && event.data.type === EVENTS.GET_INFO_EVENT) {
    event.source.postMessage(
      <GetInfoResponseEvent>{
        type: EVENTS.GET_INFO_EVENT,
        site: window.document.location.href,
        config: dot._cfg,
      },
      <WindowPostMessageOptions>'*'
    );
  }
};

export const handlerFromTopMessage = (event: MessageEvent<GetInfoResponseEvent>, dot: DOT) => {
  if (event.data.type === EVENTS.GET_INFO_EVENT && (event.data.site || event.data.config)) {
    const info = dot.getInfo();
    info.site = event.data.site;
    info.topConfig = event.data.config;
    dot.log(`iframeBridge iframe received message with site: ${info.site} and a config`);
    window.dispatchEvent(new Event(EVENTS.GET_INFO_COMPLETE));
  }
};

export const initIframeBridge = (dot: DOT) => {
  if (isTopLevel()) {
    // register child DOT subscriber / listener
    window.addEventListener('message', (event: MessageEvent<GetInfoRequestEvent>) =>
      handlerFromChildMessage(event, dot)
    );
  } else {
    // cypress hack (cypress tests are running inside iframe -> window.top is not, what expected)
    let destination: 'top' | 'parent' = 'top';
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      destination = (window as any).Cypress || (window as any).parent.Cypress ? 'parent' : 'top';
    } catch {
      // no-op
    }
    // register self in top frame (top DOT instance)
    window[destination]!.postMessage(<GetInfoRequestEvent>{ type: EVENTS.GET_INFO_EVENT }, document.referrer || '*');
    dot.log('iframeBridge: getInfo requested from ' + window.location.href);
    // listen to parent data
    window.addEventListener('message', (event: MessageEvent<GetInfoResponseEvent>) =>
      handlerFromTopMessage(event, dot)
    );
  }
};

/**
 * Configure iframe DOT instance with the top window DOT configuration
 * @param dot Instance of iframe DOT
 * @returns Promise of Config object
 */
export const configureFromTopFrame = (dot: DOT): Promise<Config | null> => {
  dot.cfg({ iframeBridge: true });

  return new Promise((resolve, reject) => {
    const cancel = window.setTimeout(() => {
      dot.log('iframeBridge timeouted', 'error');
      reject();
    }, CFG_FROM_TOP_FRAME_TIMEOUT);

    window.addEventListener(EVENTS.GET_INFO_COMPLETE, () => {
      window.clearTimeout(cancel);
      const topConfig = dot.getInfo().topConfig;
      if (!topConfig) {
        dot.log('iframeBridge failed to get top window DOT config', 'error');
        reject();
      }
      dot.cfg(topConfig);
      dot.log('iframe DOT configured from top DOT');
      resolve(topConfig);
    });
  });
};
