// Types
import { DOT } from '../../classes/dot.class';

const BEFORE_MESSAGE = 'Module Error: ';

/**
 * Prints method placeholder
 * @param dot DOT instance
 * @param moduleName name of module which includes called method
 * @param methodName name of called method
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const placeholder = (dot: DOT, moduleName: string, methodName: string, ...params): void => {
  const message = `'${methodName}' method not found, because [${moduleName}] module is not part of DOT-${dot.variant} variant`;

  dot.log(`${BEFORE_MESSAGE}${message}`, 'error');
};

// API
export default { placeholder };
