import { KeyValuePairs } from '../../../types/common';

/**
 * Traverse DOM and extract data-dot-data to object
 * @param elm Start of traversing (until document root reached)
 */
export const getDataDotData = (elm: HTMLElement): KeyValuePairs<unknown> => {
  const dataD: KeyValuePairs<unknown> = {};
  let node = elm;
  while (node && node !== document.documentElement && node.dataset) {
    const valueDotData = node.dataset.dotData;

    if (!valueDotData) {
      node = node.parentNode as HTMLElement;
      continue;
    }

    try {
      const dataDot = JSON.parse(valueDotData);
      for (const key in dataDot) {
        if (!(key in dataD)) {
          dataD[key] = dataDot[key];
        }
      }
    } catch (e) {
      // no-op
    }

    node = node.parentNode as HTMLElement;
  }

  return dataD;
};

/**
 * Get serialized list of data-dot values
 * @param elm Start of traversing (until document root reached)
 */
export const getDataDot = (elm: HTMLElement): string => {
  const arr = [];
  let node = elm;
  while (node && node !== document.documentElement && node.dataset) {
    const value = node.dataset.dot;
    if (value) {
      arr.unshift(value);
    }
    node = node.parentNode as HTMLElement;
  }
  return arr.join('/');
};

/**
 * Get serialized list of data-dot values - based on MouseEvent
 * @param evt Object's dispatched event where traversing starts (until document root reached)
 */
export const getDataDotFromEventTarget = (evt: MouseEvent): string => {
  const arr = [];
  const traversedPath = evt.composedPath() as HTMLElement[];
  traversedPath.forEach((elem) => {
    if (elem !== document.documentElement && elem.dataset?.dot) {
      arr.unshift(elem.dataset.dot);
    }
  });
  return arr.join('/');
};

/**
 * Get serialized list of css classes
 * @param elm Start of traversing (until document root reached)
 */
export const getCSSPath = (elm: HTMLElement): string => {
  const arr = [];
  let node = elm;
  while (node && node !== document.documentElement && node.dataset) {
    const value = node.getAttribute('class');
    if (value) {
      const valuesArray = value.split(' ');
      if (valuesArray.length === 1) {
        arr.unshift(value);
      } else {
        arr.unshift(valuesArray.sort().join('.'));
      }
    }
    node = node.parentNode as HTMLElement;
  }
  return arr.join('/');
};

/**
 * Get closest data-fieldvalue (converted to bool)
 * @param elm Start of traversing (until data found or document root reached)
 */
export const getDataFieldValue = (elm: HTMLElement): boolean => {
  let node = elm;
  while (node && node !== document.documentElement) {
    const value = node.getAttribute('data-fieldvalue');
    if (value !== null) {
      return value === '1';
    }
    node = node.parentNode as HTMLElement;
  }
  return true;
};

// API
export default { getDataDotData, getDataDot, getCSSPath, getDataFieldValue };
